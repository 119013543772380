import { PropsWithChildren } from "react";

export interface TitleProps {
    subtitle?: string | React.ReactElement
    rightbutton?: React.ReactElement
    margin?: string
}

export const PageTitle = (props: PropsWithChildren<TitleProps>) => {
    return (
        <div className={`flex flex-row justify-between items-start ${props.margin ? props.margin : ''}`}>
            <div className="flex flex-col">
                <h1 className="tablet:text-2xl phone:text-xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                    {props.children}
                </h1>
                {
                    props.subtitle ?
                        <div className='text-gray-600 text-sm italic dark:text-gray-200'>{props.subtitle}</div>
                        :
                        <></>
                }
            </div>
            {
                props.rightbutton ? <div>
                    {props.rightbutton}
                </div> : <></>
            }
        </div>
    )
}

export const SectionTitle = (props: PropsWithChildren<TitleProps>) => {
    return (
        <div className={`flex flex-row justify-between items-start ${props.margin ? props.margin : ''}`}>
            <div className="flex flex-col">
                <h2 className="tablet:text-xl phone:text-lg font-bold tracking-tight text-gray-900 dark:text-gray-100">
                    {props.children}
                </h2>
                {
                    props.subtitle ?
                        <div className='text-gray-600 text-sm italic dark:text-gray-200'>{props.subtitle}</div>
                        :
                        <></>
                }
            </div>
            {
                props.rightbutton ? <div>
                    {props.rightbutton}
                </div> : <></>
            }
        </div>
    )
}
