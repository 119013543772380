// Postgrest client
import { PostgrestClient, PostgrestSingleResponse } from "@supabase/postgrest-js"
// Db helper
import { insert, inserts, remove, select, update } from "./Db"
import { DbContextInterface, DbContextMessageInterface } from "../config/Db"


export const getContexts = (db: PostgrestClient<any, string, any>, workspace_id: number): Promise<Array<DbContextInterface>> => {
    return new Promise((resolve, reject) => {
        select(db, 'context', 'id,ref,workspace(id,ref,name),name,user_prompt,system_prompt,created_at')
            .eq('workspace_id', workspace_id)
            .order('updated_at', { ascending: false })
            .then((result: PostgrestSingleResponse<Array<string>>) => {
                if (result.error) {
                    reject()
                } else {
                    resolve((result.data as unknown) as Array<DbContextInterface>)
                }
            })
    })
}

export const insertContext = (db: PostgrestClient<any, string, any>, workspace_id: number, name: string): Promise<DbContextInterface> => {
    return new Promise((resolve, reject) => {
        insert(db, 'context', {
            workspace_id: workspace_id,
            name: name
        }).then((value: PostgrestSingleResponse<Array<DbContextInterface>>) => {
            if (value.error) {
                return reject()
            } else {
                return resolve(value.data[0])
            }
        })
    })
}


export const getContext = (db: PostgrestClient<any, string, any>, workspace_id: number, ref: string): Promise<DbContextInterface | undefined> => {
    return new Promise((resolve, reject) => {
        select(db, 'context', 'id,ref,workspace(id,ref,name),name,transcript,transcript_in_progress,created_at,context_message(id,role,content,hidden)')
            .eq('workspace_id', workspace_id)
            .eq('ref', ref)
            .order('id', { referencedTable: 'context_message' })
            .maybeSingle()
            .then((result: PostgrestSingleResponse<string | null>) => {
                if (result.error) {
                    return reject()
                } else {
                    return resolve((result.data as unknown) as DbContextInterface)
                }
            })
    })
}

export const deleteContext = (db: PostgrestClient<any, string, any>, workspace_id: number, ref: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        remove(db, 'context').eq('ref', ref).eq('workspace_id', workspace_id).then((result: PostgrestSingleResponse<null>) => {
            if (result.error) {
                return reject()
            }
            else {
                return resolve()
            }
        })
    })
}

export const updateContext = (db: PostgrestClient<any, string, any>, data: DbContextInterface): Promise<DbContextInterface> => {
    return new Promise((resolve, reject) => {
        const rep = { ...data }
        data.context_message = undefined
        data.workspace = undefined
        data.id = undefined
        update(db, 'context', data).eq('id', rep.id).single().then((result: PostgrestSingleResponse<DbContextInterface>) => {
            if (result.error) {
                return reject()
            }
            else {
                return resolve(result.data)
            }
        })
    })
}

export const insertContextMessages = (db: PostgrestClient<any, string, any>, messages: Array<DbContextMessageInterface>): Promise<Array<DbContextMessageInterface>> => {
    return new Promise((resolve, reject) => {
        inserts(db, 'context_message', messages).then((value: PostgrestSingleResponse<Array<DbContextMessageInterface>>) => {
            if (value.error) {
                return reject()
            } else {
                return resolve(value.data)
            }
        })
    })
}

export const deleteContextMessage = (db: PostgrestClient<any, string, any>, context_id: number, message_id: number): Promise<void> => {
    return new Promise((resolve, reject) => {
        remove(db, 'context_message').eq('id', message_id).eq('context_id', context_id).then((result: PostgrestSingleResponse<null>) => {
            if (result.error) {
                return reject()
            }
            else {
                return resolve()
            }
        })
    })
}

export const deleteContextMessages = (db: PostgrestClient<any, string, any>, context_id: number): Promise<void> => {
    return new Promise((resolve, reject) => {
        remove(db, 'context_message').eq('context_id', context_id).then((result: PostgrestSingleResponse<null>) => {
            if (result.error) {
                return reject()
            }
            else {
                return resolve()
            }
        })
    })
}
