import { useAuth } from "react-oidc-context";
import { WorkspaceProps } from "../Index";
import { useDb, select, insert, update } from "../../../services/Db";
import { PageTitle } from "../../../components/Elements/Title";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../components/Contexts/ToastContext";
import { DbRights, DbWorkspacePromptsInterface } from "../../../config/Db";
import { UserHasRights } from "../../../services/Workspace";
import { Button, Label, Spinner, Textarea } from "flowbite-react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { PostgrestSingleResponse } from "@supabase/postgrest-js";
import { useEffect, useState } from "react";


export default function WorkspacePrompts(props: WorkspaceProps) {

    const auth = useAuth()
    const db = useDb("app", auth.user?.access_token)
    const { t } = useTranslation()
    const { register, setValue, handleSubmit, formState: { isSubmitting, isDirty, isValid } } = useForm({ mode: "onChange" });
    const toast = useToast()
    const [prompts, setPrompts] = useState<DbWorkspacePromptsInterface | null>(null)

    /**
     * Transcript phase methods
     */
    const handlePromptSubmit = (data: any) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.AppWorkspacePromptReadWrite]) && prompts) {
            update(db, "workspace_prompt", data).eq('id', prompts.id).then(() => {
                toast.fire("success", "", 3000)
            })
        }
    }


    useEffect(() => {
        const getPrompts = () => {
            if (props.workspaceConfig && props.workspaceConfig.workspace) {
                select(db, "workspace_prompt").eq('workspace_id', props.workspaceConfig.workspace.id).then((values: PostgrestSingleResponse<string[]>) => {
                    if (values.error) {
                        console.error(values)
                    } else if (values.count == 0) {
                        insert(db, "workspace_prompt", {
                            workspace_id: props.workspaceConfig.workspace.id,
                            system_prompt: '',
                            user_prompt: ''
                        }).then((value: PostgrestSingleResponse<string[]>) => {
                            console.log(value.data)
                            setValue('system_prompt', "")
                            setValue('user_prompt', "")
                            if (value.data && value.data.length > 0) {
                                setPrompts(value.data[0] as unknown as DbWorkspacePromptsInterface)
                            }
                        })
                    } else {
                        const v = values.data[0] as unknown as DbWorkspacePromptsInterface
                        console.log(v)
                        setValue('system_prompt', v?.system_prompt)
                        setValue('user_prompt', v?.user_prompt)
                        setPrompts(v as unknown as DbWorkspacePromptsInterface)

                    }
                })
            }
        }
        getPrompts()
    }, [auth, props.workspaceConfig])

    return (
        <>
            <PageTitle subtitle={t('settings.prompts.subtitle')}>{t('settings.prompts.title')}</PageTitle >
            <div className="flex flex-col gap-4 mt-4">
                <form onSubmit={handleSubmit((data: any) => handlePromptSubmit(data))} className="flex flex-col gap-4">
                    <div>
                        <Label className="font-bold">{t('application.transcript.prompts.system')}</Label>
                        <Textarea rows={8} {...register("system_prompt", { required: t('application.transcript.prompt.system.required') })}></Textarea>
                    </div>
                    <div>
                        <Label className="font-bold">{t('application.transcript.prompts.user')}</Label>
                        <Textarea rows={3} {...register("user_prompt")}></Textarea>
                    </div>
                    <Button className='w-48 ml-auto' id='prompts-submit' color="submit" type="submit" disabled={!isDirty || !isValid}>
                        {t('application.transcript.prompts.button')}
                        {isSubmitting ? <Spinner className="ml-2" color="info" aria-label="Submitting spinner" />
                            : <CheckIcon className="ml-2 h-5 w-5" />}
                    </Button>
                </form>

            </div>
        </>
    )


}
