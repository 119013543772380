import { Fragment, useEffect, useRef, useState } from "react";
import { WorkspaceProps } from "../Index";
import { PageTitle } from "../../../components/Elements/Title";
import { useTranslation } from "react-i18next";
import { Alert, Button, Popover, Spinner, Textarea } from "flowbite-react";
import { FileDrop } from "../../../components/Elements/FileDrop";
import { audioBufferToWav } from "../../../services/Audio";
import { ArrowPathIcon, ArrowRightIcon, Bars4Icon, TrashIcon, WifiIcon } from "@heroicons/react/24/outline";
import { httpApiPost } from "../../../services/HttpApi";
import { useAuth } from "react-oidc-context";
import { DbContextInterface, DbContextMessageInterface, DbRights, DbWorkspacePromptsInterface } from "../../../config/Db";
import { deleteContextMessage, deleteContextMessages, getContext, insertContextMessages, updateContext } from "../../../services/Context";
import { useDb } from "../../../services/Db";
import { NameEditable } from "../../../components/Elements/NameEditable";
import Moment from "react-moment";
import { useToast } from "../../../components/Contexts/ToastContext";
import { getWorkspacePrompts, UserHasRights } from "../../../services/Workspace";
import { ChatCard } from "../../../components/Elements/Chatcard";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { AudioRecorder } from "../../../components/Elements/AudioRecorder";

export interface TranscriptAssistantProps extends WorkspaceProps {
    contextRef: string | undefined
    updateContextTitle: (identifier: number, value: string) => void
    removeCurrentContext: () => void
}

interface chatMessage {
    role: 'system' | 'user' | 'assistant' | 'human' | 'robot',
    content: string
    end?: boolean
}

const getMediaLink = (src: Blob | ArrayBuffer | string, type: string): string => {
    if (src instanceof Blob) {
        return URL.createObjectURL(src)
    } else {
        const blob = new Blob([src], { type: type })
        return URL.createObjectURL(blob)
    }
}

const downloadLink = (url: string, name: string,) => {
    const a = document.createElement('a')
    a.href = url
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}


export function ApplicationTranscriptAssistant(props: TranscriptAssistantProps) {

    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)
    const { t, i18n } = useTranslation()
    const toast = useToast()

    const [context, setContext] = useState<DbContextInterface | undefined>(undefined)
    const [audioBuffer, setAudioBuffer] = useState<ArrayBuffer | undefined>(undefined)
    const [transcriptStarted, setTranscriptStarted] = useState<boolean>(false)

    /**
     * Chat variables
     */
    const [chatStarted, setChatStarted] = useState<boolean>(false)
    const [chatMessages, setChatMessages] = useState<Array<DbContextMessageInterface>>([])
    const [lastMessage, setLastMessage] = useState<chatMessage | undefined>(undefined)

    const messagesEndRef = useRef<HTMLDivElement>(null)
    const { sendJsonMessage, readyState } = useWebSocket(
        `${import.meta.env.VITE_WS_URL}/workspaces/${props.workspace_ref}/transcript-chat`,
        {
            share: false,
            reconnectAttempts: 360,
            reconnectInterval: 10,
            shouldReconnect: () => true,
            onClose: () => { },
            onMessage: (event: WebSocketEventMap["message"]) => {
                const msg: chatMessage = JSON.parse(event.data)
                if (msg.end == true && context) {
                    insertContextMessages(db, [{
                        context_id: context.id,
                        role: 'assistant',
                        content: msg.content,
                        hidden: false
                    }]).then(() => {
                        setChatMessages(prev => { return [...prev, msg] })
                        setLastMessage(undefined)
                        scrollToBottom()
                    })
                } else {
                    setChatStarted(false)
                    setLastMessage(msg)
                    scrollToBottom()
                }
            },
        }
    )

    /**
     * Common methods
     */

    const updateContextTitle = (id: number, title: string) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            setContext(prev => {
                if (prev) {
                    return {
                        ...prev,
                        name: title
                    }
                }
            })
            props.updateContextTitle(id, title)
        }
    }

    const cleanTranscript = () => {
        if (context && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            deleteContextMessages(db, context.id || 0).then(() => {
                updateContext(db, { id: context.id, transcript: null, transcript_in_progress: false }).then(() => {
                    refreshContext()
                })
            })
        }
    }

    /**
     * Transcript execution methods
     */

    const readMedia = (file: File): Promise<void> => {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                const arrBuffer: ArrayBuffer = event.target?.result as ArrayBuffer;
                createAudio(arrBuffer).then(() => {
                    return resolve()
                })
            };
            reader.readAsArrayBuffer(file);
        })
    }

    const extractText = (file: File): Promise<void> => {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                if (context)
                    updateContext(db, {
                        id: context?.id,
                        transcript: event.target?.result as string,
                        transcript_in_progress: false
                    }).then((value: DbContextInterface) => {
                        setContext(value)
                    })
                return resolve()
            }
            reader.readAsText(file);
        })
    }

    const createAudio = (arrBuffer: ArrayBuffer): Promise<void> => {
        return new Promise((resolve, _) => {
            const audioCtx = new AudioContext({ sampleRate: 8000 });
            audioCtx.decodeAudioData(arrBuffer, function (audioBuffer: AudioBuffer) {
                //const minutes = Math.max(1, ((audioBuffer.duration * 200) / (3000 * 60))).toFixed(0)
                //setDuration(minutes) // Duration in minutes
                const buffer = audioBufferToWav(audioBuffer, false, true)
                setAudioBuffer(buffer)
                resolve()
            });
        })
    }

    const handleInput = (file: File): Promise<void> => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            if (file.type.startsWith('audio/') || file.type.startsWith('video/')) {
                return readMedia(file)
            } else if (file.type.startsWith('text/')) {
                return extractText(file)
            }
        }
        return Promise.reject()
    }

    const handleTranscript = () => {
        // Force refresh token before starting transcript
        auth.signinSilent().then(() => {
            httpApiPost(`/workspaces/${props.workspaceConfig.workspace.ref}/contexts/${context?.ref}/transcript`, audioBuffer, auth.user?.access_token || '')
                .then(() => {
                    setAudioBuffer(undefined)
                    refreshContext()
                    setTranscriptStarted(false)
                }).catch(() => {
                    refreshContext()
                    setTranscriptStarted(false)
                })
        })
    }

    /**
     * Chat phase methods
     */
    const sendMessage = (msg: string) => {
        if (context && msg.trim().length > 0 && props.workspaceConfig?.workspace && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            insertContextMessages(db, [{
                context_id: context.id,
                role: 'user',
                content: msg,
                hidden: false
            }]).then(() => {
                setChatMessages(prev => {
                    const n = [...prev, { role: 'user', content: msg }]
                    sendJsonMessage({
                        auth: { token: auth.user?.access_token },
                        messages: n
                    })
                    return n
                })
            }).catch(() => {
                toast.fire('failure', t('application.transcript.chat.error'), 3000)
            })
        }
    }

    const startConversation = () => {
        if (context && props.workspaceConfig && props.workspaceConfig.workspace) {
            Promise.all([
                getWorkspacePrompts(db, props.workspaceConfig.workspace),
                getContext(db, props.workspaceConfig.workspace.id || 0, context.ref || '')
            ]).then((values: [DbWorkspacePromptsInterface, DbContextInterface | undefined]) => {
                if (values[1]) {
                    const initialMessages: Array<DbContextMessageInterface> = [{
                        context_id: context.id,
                        role: 'system',
                        content: values[0].system_prompt,
                        hidden: true
                    },
                    {
                        context_id: context.id,
                        role: 'user',
                        content: `${values[0].user_prompt}\n${values[1].transcript}`,
                        hidden: true
                    }]
                    setChatMessages(initialMessages)
                    sendJsonMessage({
                        auth: { token: auth.user?.access_token },
                        messages: initialMessages
                    })
                }
            }).catch(() => {
                toast.fire('failure', t('application.transcript.chat.error'), 3000)
            })
        }
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'instant' })
    }

    const refreshContext = () => {
        if (props.workspaceConfig?.workspace && props.workspaceConfig.workspace.id && props.contextRef && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            getContext(db, props.workspaceConfig.workspace.id, props.contextRef).then((value: DbContextInterface | undefined) => {
                setContext(value)
                setChatMessages(value?.context_message || [])
            })
        } else {
            setContext(undefined)
        }
    }


    /**
     * UseEffects
     */
    // On contextRef of Workspace changes
    useEffect(() => {
        refreshContext()
    }, [props.workspaceConfig?.workspace, props.contextRef])

    useEffect(() => {
        if (chatMessages.length > 0) {
            scrollToBottom()
        }
    }, [chatMessages])

    useEffect(() => {
        if (context?.transcript_in_progress == true) {
            const timeoutId = setTimeout(() => {
                refreshContext()
            }, 20000);
            return () => clearTimeout(timeoutId)
        }
    }, [context]);

    return (
        <>
            {
                props.workspaceConfig?.role && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptRead, DbRights.AppTranscriptReadWrite]) && context ?
                    <>
                        {/* Title */}
                        <PageTitle
                            margin='mb-4'
                            rightbutton={
                                <div className="flex items-center flex-row gap-2">
                                    {
                                        context.transcript ?
                                            <Popover content={
                                                <div className="w-[500px] text-sm text-gray-500 dark:text-gray-400">
                                                    <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                                                        <h3 className="font-semibold text-gray-900 dark:text-white">{t('transcript')}</h3>
                                                    </div>
                                                    <div className="px-3 py-2">
                                                        <Textarea onChange={(evt) => { updateContext(db, { id: context.id, transcript: evt.target.value }).then((value: DbContextInterface) => { setContext(value) }) }} rows={10} defaultValue={context?.transcript || ''}></Textarea>
                                                    </div>
                                                </div>
                                            } placement="bottom">
                                                {
                                                    <Button aria-label='New transcript' color="transparent">
                                                        <Bars4Icon className="text-gray-800 dark:text-gray-200 h-5 w-5" />
                                                    </Button>
                                                }
                                            </Popover> : <>{
                                                context.transcript_in_progress ? <Spinner className="h-5 w-5" /> : <></>
                                            }</>
                                    }
                                    <div>
                                        <Button aria-label='New transcript' color="transparent" onClick={() => { cleanTranscript() }}>
                                            <ArrowPathIcon className="text-gray-800 dark:text-gray-200 h-5 w-5" />
                                        </Button>
                                    </div>
                                    <Button aria-label='Remove current assistant' color="transparent" onClick={() => { props.removeCurrentContext() }}>
                                        <TrashIcon className="text-red-800 dark:text-red-600 h-5 w-5" />
                                    </Button>
                                </div>

                            }
                            subtitle={<span>{t('application.transcript.context.created')} <Moment locale={i18n.language} fromNow>{context.created_at}</Moment></span>}
                        >
                            <NameEditable className='p-1' name={context.name || ''} identifier={context.id} save={updateContextTitle} maxchars={52} />
                        </PageTitle >


                        <div className="flex flex-col h-[calc(100%-116px)] gap-2 items-center w-full">
                            <div className="flex flex-col gap-2 w-full h-full items-center overflow-y-scroll no-scrollbar">
                                { /* Start transcript button, displayed only if there is a transcript and there is no chat */}
                                {
                                    context.transcript && chatMessages.filter((cm: DbContextMessageInterface) => { return cm.hidden != true }).length == 0 && !lastMessage ?
                                        <div className="flex flex-col gap-2 items-center w-full h-full justify-center">
                                            <div>
                                                <Button
                                                    disabled={chatStarted}
                                                    color="action"
                                                    className="px-8"
                                                    aria-label={t('application.transcript.chat.start')}
                                                    onClick={() => { setChatStarted(true); startConversation() }}
                                                >{t('application.transcript.chat.start')} {chatStarted ? <Spinner className="ml-2 h-5 w-5"></Spinner> : <ArrowRightIcon className="ml-2 h-5 w-5"></ArrowRightIcon>}</Button>
                                            </div>
                                        </div> : <></>
                                }
                                { /* Drag&Drop or record, displayed if no chat message and no transcript */}
                                {
                                    !context.transcript && chatMessages.filter((cm: DbContextMessageInterface) => { return cm.hidden != true }).length == 0 ?
                                        <div className="flex flex-col gap-2 items-center w-full h-full justify-center" >
                                            {
                                                context && context.transcript_in_progress ?
                                                    <Alert color='failure'>{t('application.transcript.bepatient', { minutes: "a couple of" })}</Alert> : <>{
                                                        audioBuffer === undefined ?
                                                            <div className="w-full mt-2 flex flex-row gap-2 flex-wrap items-stretch h-96">
                                                                <div className="flex-1 h-full">
                                                                    <FileDrop width="w-full h-full" text='application.transcript.filedrop' fileHandler={handleInput}></FileDrop>
                                                                </div>
                                                                <div className="flex-1">
                                                                    <AudioRecorder width="w-full h-full" handle={createAudio}></AudioRecorder>
                                                                </div>
                                                            </div>
                                                            :
                                                            <>
                                                                <figure className="ml-auto mr-auto w-96">
                                                                    <audio className="w-full" controls src={getMediaLink(audioBuffer, 'audio/wav')}></audio>
                                                                </figure>
                                                                <div className="mt-4">
                                                                    {
                                                                        readyState == ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite]) ?
                                                                            <Button disabled={audioBuffer === undefined || transcriptStarted} color="action" onClick={() => { setTranscriptStarted(true); handleTranscript() }} className="ml-auto mr-auto px-8 mt-4">{t('application.transcript.start')} {transcriptStarted ? <Spinner className="ml-2 h-5 w-5" /> : <ArrowRightIcon className="ml-2 h-5 w-5"></ArrowRightIcon>}</Button>
                                                                            :
                                                                            <div>
                                                                                <Alert color="failure" icon={WifiIcon}>
                                                                                    <span className="font-bold">{t('application.transcript.wait')}</span>
                                                                                </Alert>
                                                                            </div>

                                                                    }
                                                                </div>
                                                            </>
                                                    }</>
                                            }
                                        </div>
                                        : <></>
                                }
                                { /* Chat messages */}
                                {
                                    chatMessages.map((value: DbContextMessageInterface, index: number) => (
                                        <Fragment key={index}>
                                            {
                                                value.hidden != true ? <ChatCard
                                                    key={value.id}
                                                    role={value.role}
                                                    user={auth.user?.profile}
                                                    remove={() => {
                                                        deleteContextMessage(db, context.id || 0, value?.id || 0).then(() => {
                                                            setChatMessages(prev => {
                                                                return prev.filter((m: DbContextMessageInterface) => { return m.id != value.id })
                                                            })
                                                        })
                                                    }}
                                                    download={() => {
                                                        downloadLink(getMediaLink(value.content, 'text/markdown'), `${context.name}.md`)
                                                    }}
                                                >{value.content}</ChatCard>
                                                    : <></>
                                            }
                                        </Fragment>
                                    ))
                                }
                                { /* Last chat message from assistant to display running answer */}
                                {
                                    lastMessage ? <ChatCard role={lastMessage.role} user={auth.user?.profile}>{lastMessage.content}</ChatCard> : <></>
                                }
                                <div ref={messagesEndRef} className='justify-end items-end'></div>
                            </div>
                        </div>
                        {
                            chatMessages.filter((cm: DbContextMessageInterface) => { return cm.hidden != true }).length > 0 ?
                                <div className="flex flex-col justify-end items-center bottom-4 w-full bg-transparent">
                                    <div className="text-xs text-gray-500 w-2/3">{
                                        readyState == ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite]) ?
                                            t('application.transcript.chat.submit') :
                                            t('application.transcript.wait')
                                    }</div>
                                    <NameEditable
                                        className='mb-2 mx-4 shadow-lg w-2/3 px-2 py-3 rounded rounded-lg border border-primary-700 max-h-80 min-h-20 overflow-y-scroll'
                                        name=""
                                        editable={readyState == ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite])}
                                        needSubmit={(event) => { return (event.ctrlKey && event.key == 'Enter') || (event.metaKey && event.key == 'Enter') }}
                                        save={(_: number, value: string) => { sendMessage(value) }}
                                    />
                                </div> : <></>
                        }
                    </> : <></>
            }
        </>
    )
}